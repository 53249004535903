document.addEventListener('DOMContentLoaded', function () {


    fixedHeader();

    function fixedHeader() {
        const header = document.getElementById('header');

        document.addEventListener('scroll', function (e) {

            let scrolled = document.documentElement.scrollTop;
            if (scrolled > 100) {
                if (!header.classList.contains('fixed')) {
                    header.classList.add('fixed');
                }
            } else {
                header.classList.remove('fixed');
            }
        })
    }
});